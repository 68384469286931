import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Header from '../../components/header'
// Footer elements
import CornellLogo from '../../svg/cornell-logo.svg'
import IpmLogo from '../../svg/ipm-logo.svg'
import UsdaLogo from '../../svg/usda-logo.svg'
import AccessibilityLogo from '../../svg/accessible.svg'
import AcisLogo from '../../svg/acis-logo.svg'
import packageJson from '../../../package.json'

const { version } = packageJson

// Set the data query to NRCC API
function Response() {
    const currentYear = new Date().getFullYear()
    const sid = 'hur newa'
    const sdate = `${currentYear}010100`
    const edate = 'now'
    const [data, setData] = useState(null);
    const [pastHourRain, setPastHourRain] = useState(null)
    const [pastTwentyFourHoursRain, setPastTwentyFourHoursRain] = useState(null)
    const [lastSevenDaysRain, setLastSevenDaysRain] = useState(null)
    const [pastFourWeeksRain, setPastFourWeeksRain] = useState(null)
    const [annualRain, setAnnualRain] = useState(0)
    const url = 'https://hrly.nrcc.cornell.edu/stnHrly';
    const params = useMemo(() => ({
        sid: sid,
        sdate: sdate,
        edate: edate,
    }), [sid, sdate, edate]);

    
    useEffect(() => {
        axios.post(url, params)
            .then(response => {
                console.log(response.data)
                setData(response.data);
            })
            .catch(error => {
                console.error(`An error occurred: ${error}`);
            });
    }, [url,params]);


    // Past hour rainfall
    useEffect(() => {
        if (data !== null) {
            let value = parseFloat(data.hrlyData[data.hrlyData.length - 1][2]);
            let pastHourRain = !isNaN(value) ? Math.round(value * 10) / 10 : 0;
            setPastHourRain(pastHourRain);
        }
    }, [data]);

    // Past Twenty Four Hours rainfall
    useEffect(() => {
        if (data !== null) {
            // Get the current date and time
            const now = new Date();
            // Get the date and time 24 hours ago
            const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    
            // Filter the hrlyData array to only include data from the last 24 hours
            const lastTwentyFourHoursData = data.hrlyData.filter(item => {
                // Parse the date and time from the item
                const itemDate = new Date(item[0]);
                // Only include the item if its date is after twentyFourHoursAgo
                return itemDate > twentyFourHoursAgo;
            });
    
            // Calculate the cumulative sum from the filtered array
            let pastTwentyFourHoursRain = lastTwentyFourHoursData.reduce((sum, item) => {
                let value = parseFloat(item[2]);
                if (!isNaN(value)) {
                    return sum + value;
                }
                else {
                    return sum;
                }
            }, 0);
    
            pastTwentyFourHoursRain = Math.round(pastTwentyFourHoursRain * 10) / 10;
            setPastTwentyFourHoursRain(pastTwentyFourHoursRain);
        }
    }, [data]);
    
    

    // Past week rainfall
    useEffect(() => {
        if (data !== null) {
            // Get the current date and time
            const now = new Date();
            // Get the date and time seven days ago
            const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    
            // Filter the hrlyData array to only include data from the last 7 days
            const lastSevenDaysData = data.hrlyData.filter(item => {
                // Parse the date and time from the item
                const itemDate = new Date(item[0]);
                // Only include the item if its date is after sevenDaysAgo
                return itemDate > sevenDaysAgo;
            });
    
            // Calculate the cumulative sum from the filtered array
            let lastSevenDaysRain = lastSevenDaysData.reduce((sum, item) => {
                let value = parseFloat(item[2]);
                if (!isNaN(value)) {
                    return sum + value;
                }
                else {
                    return sum;
                }
            }, 0);
    
            lastSevenDaysRain = Math.round(lastSevenDaysRain * 10) / 10;
            setLastSevenDaysRain(lastSevenDaysRain);
        }
    }, [data]);

    // Past four weeks rainfall
    useEffect(() => {
        if (data !== null) {
            // Get the current date and time
            const now = new Date();
            // Define the number of days to look back
            const fourWeeks = 28;
            // Get the date and time fourWeeks days ago
            const fourWeeksAgo = new Date(now.getTime() - fourWeeks * 24 * 60 * 60 * 1000);
    
            // Filter the hrlyData array to only include data from the last fourWeeks days
            const lastFourWeeksData = data.hrlyData.filter(item => {
                // Parse the date and time from the item
                const itemDate = new Date(item[0]);
                // Only include the item if its date is after fourWeeksAgo
                return itemDate > fourWeeksAgo;
            });
    
            // Calculate the cumulative sum from the filtered array
            let pastFourWeeksRain = lastFourWeeksData.reduce((sum, item) => {
                let value = parseFloat(item[2]);
                if (!isNaN(value)) {
                    return sum + value;
                }
                else {
                    return sum;
                }
            }, 0);
    
            pastFourWeeksRain = Math.round(pastFourWeeksRain * 10) / 10;
            setPastFourWeeksRain(pastFourWeeksRain);
        }
    }, [data]);
    
    


    // Annual rainfall
    useEffect(() => {
        if (data !== null) {
            let annualRain = data.hrlyData.reduce((sum, item) => {
                let value = parseFloat(item[2])
                if (!isNaN(value)) {
                    return sum + value;
                } else {
                    return sum;
                }
            }, 0);
            annualRain = Math.round(annualRain * 10) / 10;
            setAnnualRain(annualRain)
        }
    }, [data]);
    

    
    
    return (
        <div className="mx-auto bg-white">
            <Header></Header>
                <main id="main" className="aria-target" tabIndex="-1">
                    <section aria-label="Station" className="pd-12 bg-blue-10">
                        <div className="container w-4/5 py-4 mx-auto">
                            <h1><b>Hurley, NY</b></h1>   
                            <h3>Courtesy of <a href="https://hvfarmhub.org" target="blank">Hudson Valley Farm Hub</a></h3>                         
                            <br></br>
                            <h2><b>Conditions last hour</b></h2>
                            {data && data.hrlyData
                                ? <h4>Updated {new Date(data.hrlyData[data.hrlyData.length - 1][0]).toLocaleString("en-US", {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                  })}</h4>
                                : <h4>Loading...</h4>
                            }
                            {data && data.hrlyData
                                ? <p>
                                    Temperature is <b>{data.hrlyData[data.hrlyData.length - 1][3]}˚F</b><br></br>
                                    Dewpoint is <b>{data.hrlyData[data.hrlyData.length - 1][5]}˚F</b><br></br>
                                    Relative humidity is <b>{data.hrlyData[data.hrlyData.length - 1][4]}%</b><br></br>
                                    Average windspeed is <b>{data.hrlyData[data.hrlyData.length - 1][7]} mph</b><br></br>
                                    Wind direction is <b>{data.hrlyData[data.hrlyData.length - 1][8]}˚</b><br></br>
                                </p>
                                : <p>Loading...</p>
                            }
                            
                            <br></br>
                            <h2><b>Rainfall History</b></h2>
                            {data && data.hrlyData
                            ? <p>
                                <b>{pastHourRain} inches</b> in the past hour<br></br>
                                <b>{pastTwentyFourHoursRain} inches</b> in the past twenty four hours<br></br>
                                <b>{lastSevenDaysRain} inches</b> in the past seven days<br></br>
                                <b>{pastFourWeeksRain} inches</b> in the past twenty eight days<br></br>
                                <b>{annualRain} inches</b> since January 1<br></br>
                            </p>
                            : <p>Loading...</p>
                            }
                            <br></br>

                            <h2><b>Forecast</b></h2>
                            <h4>Mix = partly cloudy</h4>
                            {data && data.dlyFcstData
                                ? <p>
                                    <b>{new Date(data.dlyFcstData[0][0]).toLocaleDateString("en-US")}.</b> High of {Math.round(data.dlyFcstData[0][1])}˚F, low of {Math.round(data.dlyFcstData[0][2])}˚F, and {data.dlyFcstData[0][3]}.<br></br>
                                    <b>{new Date(data.dlyFcstData[1][0]).toLocaleDateString("en-US")}.</b> High of {Math.round(data.dlyFcstData[1][1])}˚F, low of {Math.round(data.dlyFcstData[1][2])}˚F, and {data.dlyFcstData[1][3]}.<br></br>
                                    <b>{new Date(data.dlyFcstData[2][0]).toLocaleDateString("en-US")}.</b> High of {Math.round(data.dlyFcstData[2][1])}˚F, low of {Math.round(data.dlyFcstData[2][2])}˚F, and {data.dlyFcstData[2][3]}.<br></br>
                                    <b>{new Date(data.dlyFcstData[3][0]).toLocaleDateString("en-US")}.</b> High of {Math.round(data.dlyFcstData[3][1])}˚F, low of {Math.round(data.dlyFcstData[3][2])}˚F, and {data.dlyFcstData[3][3]}.<br></br>
                                    <b>{new Date(data.dlyFcstData[4][0]).toLocaleDateString("en-US")}.</b> High of {Math.round(data.dlyFcstData[4][1])}˚F, low of {Math.round(data.dlyFcstData[4][2])}˚F, and {data.dlyFcstData[4][3]}.<br></br>
                                    <b>{new Date(data.dlyFcstData[5][0]).toLocaleDateString("en-US")}.</b> High of {Math.round(data.dlyFcstData[5][1])}˚F, low of {Math.round(data.dlyFcstData[5][2])}˚F, and {data.dlyFcstData[5][3]}.<br></br>
                                </p>
                                : <p>Loading...</p>
                            }
                            
                        </div>
                        <div className="container w-4/5 py-4 mx-auto">
                        </div>
                    </section>

                </main>
                <footer className="bg-white">
                <section aria-label="Disclaimer" className="pb-12 bg-gray-50">
                    <div className="container w-4/5 py-4 mx-auto">
                    <h2 className="flex justify-center text-3xl font-bold tracking-wider text-center md:text-4xl">
                        Disclaimer
                    </h2>
                    <p className="m-auto mt-4 text-base leading-relaxed text-justify">
                        Accuracy of the weather data is the responsibility of the owners of
                        the weather station instruments. NEWA is not responsible for
                        accuracy of the weather data collected by instruments in the
                        network. If you notice erroneous or missing weather data,
                        <a href="mailto:support@newa.zendesk.com" className="ml-1">
                        contact NEWA
                        </a>{' '}
                        and we will contact the owner of the instrument.{' '}
                        <strong>
                        In no event shall Cornell University or any weather station be
                        liable to any party for direct, indirect, special, incidental, or
                        consequential damages, including lost profits, arising out of the
                        use of NEWA.
                        </strong>
                    </p>
                    </div>
                </section>

                <section aria-label="Partners" className="bg-white">
                    <div className="mx-auto my-12 max-w-screen-2xl sm:px-6 lg:px-8">
                    <div className="flex flex-col flex-wrap items-center justify-center">
                        <div className="mb-14">
                        <h2 className="text-3xl font-bold">Brought to You By</h2>
                        </div>

                        <div className="grid w-full grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3">
                        {/* CORNELL */}
                        <span className="w-64 justify-self-center">
                            <a
                            href="https://www.cornell.edu"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-red-500"
                            >
                            <CornellLogo alt="Cornell University"></CornellLogo>
                            </a>
                        </span>

                        {/* IPM */}
                        <span className="w-64 justify-self-center">
                            <a
                            href="https://nysipm.cornell.edu"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fill-current"
                            >
                            <IpmLogo alt="New York State Integrated Pest Management Program"></IpmLogo>
                            </a>
                        </span>

                        {/* ACIS */}
                        <span className="w-64 justify-self-center">
                            <a
                            href="http://www.nrcc.cornell.edu"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fill-current"
                            >
                            <AcisLogo alt="Northeast Regional Climate Center"></AcisLogo>
                            </a>
                        </span>

                        {/* USDA */}
                        <span className="w-64 justify-self-center">
                            <a
                            href="https://nifa.usda.gov/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fill-current"
                            >
                            <UsdaLogo alt="United State Department of Agriculture, National Institute of Food and Agriculture"></UsdaLogo>
                            </a>
                        </span>
                        </div>
                    </div>
                    </div>
                </section>

                <section
                    aria-label="Copyright and Accessibility"
                    className="px-4 bg-gray-50 md:px-0"
                >
                    <div className="px-4 py-8 mx-auto max-w-screen-2xl">
                    <div className="flex flex-col items-center justify-center md:max-w-2xl md:mx-auto md:flex-row">
                        <AccessibilityLogo className="w-12 h-12 mr-2 text-red-700 md:w-20 md:h-20"></AccessibilityLogo>
                        <p className="text-center md:text-left">
                        If you have a disability and are having trouble accessing
                        information on this website or need materials in an alternate
                        format, contact{' '}
                        <a href="mailto:web-accessibility@cornell.edu">
                            web-accessibility@cornell.edu
                        </a>{' '}
                        for assistance.
                        </p>
                    </div>

                    <div className="flex flex-col items-center justify-center pb-4 mt-6 text-sm md:flex-row">
                        <div>
                        © 1996-{new Date().getFullYear()} <a href="https://cals.cornell.edu/new-york-state-integrated-pest-management" target="_blank" rel="noreferrer">New York State Integrated Pest Management</a> | <a href="https://cals.cornell.edu" target="_blank" rel="noreferrer">College of Agriculture and Life Sciences at Cornell University</a>
                        </div>
                    </div>
                    <div className="pb-4 font-bold text-center">Version: {version}</div>
                    </div>
                </section>
                </footer>
        </div>
    )
}
    
    

    

export default Response

